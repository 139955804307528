import Vue from 'vue';
import Router from 'vue-router';
import Notifications from 'vue-notification';
// import Notify from '@wcjiang/notify';

Vue.use(Router);
Vue.use(Notifications);

export const constantRoutes = [{
    path: '/dashboard',
    name: 'dashboard',
    title: '首页',
    component: () => import('@/components/DashboardPage'),
    children: [{
        path: 'welcome',
        name: 'welcome',
        meta: {
          title: '欢迎您'
        },
        component: () => import('@/components/WelcomePage')
      },
      {
        path: 'analysis',
        name: 'analysis',
        meta: {
          title: '统计分析'
        },
        component: () => import('@/components/Analysis/AnalysisPage')
      },
      {
        path: 'news',
        name: 'news',
        meta: {
          title: '新闻资讯'
        },
        component: () => import('@/components/News/NewsPage')
      },
      {
        path: 'news/add',
        name: 'news_add',
        meta: {
          title: '资讯添加'
        },
        component: () => import('@/components/News/NewsAddPage')
      },

      {
        path: 'recuirt',
        name: 'recuirt',
        meta: {
          title: '人才招聘'
        },
        component: () => import('@/components/Recuirt/RecuirtPage')
      },
      {
        path: 'recuirt/add',
        name: 'recuirt_add',
        meta: {
          title: '添加岗位'
        },
        component: () => import('@/components/Recuirt/RecuirtAddPage')
      },

      {
        path: 'company',
        name: 'company',
        meta: {
          title: '公司资料'
        },
        component: () => import('@/components/Company/CompanyPage')
      },
      
      {
        path: 'version',
        name: 'version',
        meta: {
          title: '版本信息'
        },
        component: () => import('@/components/Company/VersionDesc')
      },

      {
        path: 'goods',
        name: 'goods',
        meta: {
          title: '产品供应'
        },
        component: () => import('@/components/Goods/GoodsPage')
      },
      {
        path: 'goods/add',
        name: 'goods_add',
        meta: {
          title: '添加商品'
        },
        component: () => import('@/components/Goods/GoodsAddPage')
      },
      {
        path: 'category',
        name: 'category',
        meta: {
          title: '分类管理'
        },
        component: () => import('@/components/Category/CategoryPage')
      },
      {
        path: 'category/add',
        name: 'category_add',
        meta: {
          title: '分类添加'
        },
        component: () => import('@/components/Category/CategoryAddPage')
      },
      {
        path: 'order',
        name: 'order',
        meta: {
          title: '订单列表'
        },
        component: () => import('@/components/Order/OrderPage')
      },
      {
        path: 'order/oppo',
        name: 'order_oppo',
        meta: {
          title: '询单管理'
        },
        component: () => import('@/components/Order/OppoPage')
      },
      {
        path: 'order/detail',
        name: 'order_detail',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/components/Order/OrderDetailPage')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/components/Settings/NoticePage')
      },
      {
        path: 'ad',
        name: 'ad',
        meta: {
          title: '广告管理'
        },
        component: () => import('@/components/Ad/AdPage')
      },
      {
        path: 'ad/add',
        name: 'ad_add',
        meta: {
          title: '添加广告'
        },
        component: () => import('@/components/Ad/AdAddPage')
      },

      {
        path: 'admin',
        name: 'admin',
        meta: {
          title: '管理员'
        },
        component: () => import('@/components/Admin/AdminPage')
      },
      {
        path: 'admin/add',
        name: 'admin_add',
        meta: {
          title: '修改密码'
        },
        component: () => import('@/components/Admin/AdminAddPage')
      },
      {
        path: 'settings/showset',
        name: 'showset',
        component: () => import('@/components/Showset/ShowSetPage')
      }, //applytransfer
      {
        path: 'brandlist',
        name: 'brandlist',
        component: () => import('@/components/Brand/BrandPage')
      },
      {
        path: 'brandadd',
        name: 'brandadd',
        component: () => import('@/components/Brand/BrandAddPage')
      },
      {
        path: 'brandcatelist',
        name: 'brandcatelist',
        component: () => import('@/components/Brand/BrandCatePage')
      },
      {
        path: 'brandcateadd',
        name: 'brandcateadd',
        meta: {
          title: '分类添加'
        },
        component: () => import('@/components/Brand/BrandCateAddPage')
      },
      {
        path: 'brandgoodslist',
        name: 'brandgoodslist',
        meta: {
          title: '商品管理'
        },
        component: () => import('@/components/Brand/BrandGoodsPage')
      },
      {
        path: 'brandgoodscate',
        name: 'brandgoodscate',
        meta: {
          title: '分类咨询'
        },
        component: () => import('@/components/Brand/BrandGoodsCatePage')
      },
      {
        path: 'brandgoodscateadd',
        meta: {
          title: '添加分类'
        },
        name: 'brandgoodscateadd',
        component: () => import('@/components/Brand/BrandGoodsCateAddPage')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/LoginPage')
  },
  {
    path: '*',
    redirect: '/dashboard'
  }
];

export default new Router({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});