/*
 * @Author: your name
 * @Date: 2021-05-26 15:30:28
 * @LastEditTime: 2021-07-28 12:39:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /admin/src/main.js
 */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementUI from 'element-ui';
import treeTransfer from 'el-tree-transfer';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.scss';

import App from './App';
import router from './router';
import store from './store';
import api from './config/api';
// vue-quill-edit
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// element-ui 跟 bootstrap css 有冲突
// import 'bootstrap/js/modal.js'
// import 'bootstrap/js/dropdown.js'
// import 'bootstrap/js/tooltip.js'
// // import 'bootstrap/dist/css/bootstrap.css'
// import 'font-awesome/css/font-awesome.css'
// import 'summernote'
// import 'summernote/dist/lang/summernote-zh-CN.js'
// import 'summernote/dist/summernote.css'

Vue.use(VueAxios, axios);
Vue.use(ElementUI);

// add设置调试模式
Vue.config.devtools = true;
// Vue.config.devtools = __ENV__.NODE_ENV !== 'production';

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token') || '';

  //配置接口信息
  // Axios.defaults.baseURL = 'http://www.地址.com:8360/admin/';
  axios.defaults.baseURL = api.rootUrl;
  axios.defaults.headers.common['I-WanBo-Token'] = token;

  if (!token && to.name !== 'login') {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
});

if (!process.env.IS_WEB) {
  // Vue.use(require('vue-electron'))
}
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  el: '#app',
  data: {
    new_order_count: window.localStorage.getItem('new_order_count') || 0,
    new_dynamic_count: window.localStorage.getItem('new_dynamic_count') || 0,
    new_user_count: window.localStorage.getItem('new_user_count') || 0
  },
  router,
  store,
  render: (h) => h(App),

  // mounted: function () {
  //   var audio = new Audio('/ordermsg.mp3');
  //   var that = this;
  //   setInterval(function () {
  //     axios.get('/remind/deilveryCount').then((response) => {
  //       let newcount = response.data.data;
  //       if (newcount > that.new_order_count) {
  //         audio.play();
  //         that.$notify({
  //           title: '订单提醒',
  //           message: '新订单' + newcount + '条，请及时关注',
  //           type: 'success'
  //         });
  //       }
  //       that.new_order_count = newcount;
  //       window.localStorage.setItem('new_order_count', newcount);
  //     });
  //   }, 60000);

  //   var msgaudio = new Audio('/msg.mp3');
  //   setInterval(function () {
  //     axios.get('/remind/dynamicCount').then((response) => {
  //       let newcount = response.data.data;
  //       if (newcount > that.new_dynamic_count) {
  //         msgaudio.play();
  //         that.$notify({
  //           title: '用户访问通知',
  //           message: '有用户正在访问万玻传奇，抓紧跟进！',
  //           type: 'warning'
  //         });
  //       }
  //       that.new_dynamic_count = newcount;
  //       window.localStorage.setItem('new_dynamic_count', newcount);
  //     });
  //   }, 10000);

  //   var registermsg = new Audio('/registermsg.mp3');
  //   setInterval(function () {
  //     axios.get('/remind/userCount').then((response) => {
  //       let newcount = response.data.data;
  //       if (newcount > that.new_user_count) {
  //         registermsg.play();
  //         that.$notify({
  //           title: '新用户注册',
  //           message: '新用户刚注册万玻传奇，火速联系！',
  //           type: 'success'
  //         });
  //       }
  //       that.new_user_count = newcount;
  //       window.localStorage.setItem('new_user_count', newcount);
  //     });
  //   }, 5000);
  // }
}).$mount('#app');
