const rootUrl = 'https://api.m-glass.cn/business/';
// const rootUrl = 'http://192.168.3.66:8360/business/';
// const rootUrl = 'http://127.0.0.1:8360/business/';
const api = {
  rootUrl: rootUrl,
  qiniu: 'https://up.qiniu.com',
  publicQiniu: 'https://up-z2.qiniup.com'

};

// import api from './config/api'
// Axios.defaults.baseURL = api.rootUrl;

export default api;
